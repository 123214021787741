@import '../base/variables';

.ReactTable.BaseTable {
	border: none;

	.rt-table {
		overflow-y: hidden;
		border: 1px solid rgba(0, 0, 0, 0.1);
		position: relative;
	}

	.rt-tbody {
		min-height: 35px;
		//border: 1px solid rgba(0, 0, 0, 0.1);

		> div:not(.rt-tr-group) {
			overflow-x: auto !important;
			width: 100%;
		}

		.rt-tr-group {
			height: auto !important; //overwrite height by js
			border-right: 1px solid $passiveLighter;
		}
	}

	.rt-tr {
		//this seems to fix sync between header and table borders
		width: 100% !important;
		&.-even {
			background-color: $passiveExtraLight
		}
		&.-odd {
			background-color: $passiveExtraLight
		}

		&:hover {
			background-color: $passiveLight
		}
	}

	.rt-td {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 35px;
		border-right: 1px solid $passiveLighter;
		white-space: inherit;
	}

	.rt-thead {
		background-color: $passiveMedium;
	}

	.rt-th {
		line-height: 1.8;
		color: $light;
		font-family: font-light, sans-serif;
		border-right: 1px solid $light;
		background-color: $passiveMedium;
		box-shadow: none !important;

		//show sort icons only if columns are sortable
		&.-cursor-pointer {
			background-image: url('images/svg/fs-sort-inactive.svg');
			background-repeat: no-repeat;
			background-size: 22px;
			background-position: 96%;
			padding: 5px 25px 5px 5px !important;

			&.-sort-asc {
				background-image: url('images/svg/fs-sort-asc.svg');
			}

			&.-sort-desc {
				background-image: url('images/svg/fs-sort-active.svg');
			}
		}
	}

	.rt-td.hidden:not([class*='rt-pivot']) {
		padding: 0 !important;
	}
	.rt-noData {
		top: 50px;
		background-color: transparent;
		z-index: 0;
	}
}

.ReactTable.TreeTable {
	.rt-resizable-header.-hidden {
		display: none;
	}

	.rt-pivot {
		justify-content: left !important;
	}

	.rt-pivot {
		justify-content: flex-start !important;
		padding: 7px 15px !important;
		background-color: #d4eef9;
	}

	.rt-tr {
		.rt-td.hidden:not(.rt-pivot) {
			display: none !important;
		}

		.expandRowPlus,
		.expandRowMinus {
			position: relative;
			display: inline-block;
			width: 25px;
			height: 13px;
			margin: 0;

			&:before,
			&:after {
				display: inline-block;
				position: absolute;
				top: 0;
				left: 0;
				content: '';
				width: 16px;
				height: 16px;
				border-radius: 50%;
			}

			&:before {
				background: $passiveMedium;
			}
		}

		.expandRowPlus {
			&:after {
				background: url('images/svg/plus-ico.svg') no-repeat center / 8px;
			}
		}

		.expandRowMinus {
			&:after {
				background: url('images/svg/minus-ico.svg') no-repeat center / 8px;
			}
		}
	}
}

//PDM TABLE HACK
.pdmdatamanager .rt-table {
	overflow-x: auto !important;
}
