@import '../base/variables';

p {
	font-size: 15px;
	font-weight: 400;
	letter-spacing: normal;
	line-height: 1.5;
	margin: 0 0 10px;
	font-style: normal;
	white-space: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: inherit;
	line-height: 1.2;
	color: inherit;
	margin-top: 10px;
	margin-bottom: 10px;
}

h1 {
	color: $primary;
	font-weight: 300;
	font-size: 30px;

	&.xxl {
		font-size: 60px;
	}
}

h2 {
	font-family: font-bold, sans-serif;
	font-weight: 400;
	font-size: 22px;
}

h3 {
	font-weight: 600;
	font-size: 17px;
}

h4 {
	font-size: 16px;
	font-family: font-bold, sans-serif;
	font-weight: normal;
	color: $passiveMedium;
}

h5 {
	font-weight: 600;
	font-size: 12px;
}

h6 {
	font-weight: 300;
	font-size: 10px;
}

text {
	font-family: font-reg, sans-serif;
	font-weight: normal;
}

label {
	font-size: 15px;
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
}
