// Helpers for display, flex, width, hide, positions, cursors ...
// NOTE: never use two helpers that can cancel each other
//----------------------------------------------------------------------------------------------------------------------
//
@import '../base/variables';

.h-clearfix:before,
.h-clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
	content: ' ' !important;
	display: table !important;
}

.h-clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
	clear: both !important;
}

//Bootstrap 3 center-block helper
.h-centerBlock {
	display: block !important;
	margin-left: auto !important;
	margin-right: auto !important;
}

//Bootstrap 3 pull-right helper
.h-pullRight {
	float: right !important;
}
//Bootstrap 3 pull-left helper
.h-pullLeft {
	float: left !important;
}

//Bootstrap 3 this will be 100% wide even if it's inside container-fluid
.h-containerFluidOverwrite {
	margin: 0 -30px;
}

.h-pullLeft--deskOnly {
	@media (max-width: 1340px) {
		float: none !important;
		width: 100% !important;
	}
}

.h-pullLeft--mobOnly {
	@media (max-width: $tabletPortrait) {
		float: left !important;
	}
}

.h-pullLeft--mobOnlyClear {
	@media (max-width: $tabletPortrait) {
		clear: left !important;
	}
}

.h-clear {
	clear: both !important;
}

.h-centerVerticallyAndHorizontally {
	position: fixed !important;
	top: 0 !important;
	bottom: 0 !important;
	left: 0 !important;
	right: 0 !important;
	width: auto !important;
	height: auto !important;
	padding: 0 !important;
	margin: 0 !important;
	text-align: center !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.h-displayBlock {
	display: block !important;
}

.h-displayInline {
	display: inline-block !important;
}

.h-tableCenter {
	display: table;
	margin: auto;
}

.h-hidden {
	display: none !important;
}

.h-fullWidth {
	width: 100% !important;
}

.h-fullHeight {
	height: 100% !important;
}

.h-fullWidth--mobOnly {
	@media (max-width: $tabletPortrait) {
		width: 100% !important;
	}
}

.h-minWidthButton {
	min-width: 145px !important;
}

.h-displayFlex {
	display: flex !important;
}

.h-flexWrap {
	flex-wrap: wrap !important;
}

.h-flexGrShBa {
	flex: 1 1 auto !important;
}

.h-flexGr {
	flex: 1 !important;
}

.h-flexCol {
	flex-direction: column !important;
}

.h-flexJustifyCenter {
	justify-content: center !important;
}

.h-flexJustifyFlexEnd {
	justify-content: flex-end !important;
}

.h-flexAlignItemsCenter {
	align-items: center !important;
}

.h-flexCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}

.h-flexRightOrCenter {
	display: flex;
	justify-content: flex-end;
}

@media (max-width: $tabletPortrait) {
	&.h-flexRightOrCenter {
		justify-content: center;
	}
}

.h-overflowVisible {
	overflow: visible !important;
}

.h-overflowHidden {
	overflow: hidden !important;
}

.h-overflowAuto {
	overflow: auto !important;
}

.h-absolute {
	position: absolute !important;
	z-index: 20;
}

.h-relative {
	position: relative !important;
}

.h-pointer {
	cursor: pointer !important;
}

.h-noVisibility {
	visibility: hidden !important;
}

.h-invisibleDiv {
	position: absolute;
	display: inline-block;
	width: 100%;
	height: 42px;
	cursor: not-allowed;
}

.h-resizeVerticalOnly {
	resize: vertical;
}

.h-verticalAlignTop {
	vertical-align: top;
}

.h-stretchMobile {
	position: inherit !important;
}

@media (max-width: $tabletPortrait) {
	.h-stretchMobile {
		position: absolute !important;
		top: 0 !important;
		bottom: 0 !important;
		left: 0 !important;
		right: 0 !important;
		z-index: 5 !important;
	}
}

.h-deskRightMobileCenter {
	float: right !important;
	margin-left: 15px;

	@media (max-width: 520px) {
		width: 100% !important;
		margin-left: 0;
		margin-right: 0 !important;
		margin-bottom: 10px !important;
	}
}
