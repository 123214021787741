// OverviewInfoBox
//----------------------------------------------------------------------------------------------------------------------
//
@import '../base/variables';

.OverviewInfo {
	color: $passiveDark;
	display: flex;
	flex-wrap: wrap;

	.OverviewInfo__text {
		font-size: 16px;
		font-weight: 300;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 100%;
	}

	.OverviewInfo__textInner {
		margin-right: 10px;
		text-overflow: ellipsis;
		&:first-of-type {
			//color: $alternative;
		}
	}
}
