// Helpers for text
// NOTE: never use two helpers that can cancel each other
//----------------------------------------------------------------------------------------------------------------------
//
@import '../base/variables';

$default-animation: 0.2s ease-out;

.h-fadeIn {
	animation: fadeInAnimation $default-animation;
}

@keyframes fadeInAnimation {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.h-slideUp {
	animation: slideUpAnimation 400ms ease-out forwards;
}

@keyframes slideUpAnimation {
	from {
		opacity: 0;
		transform: translateY(30px);
	}
	to {
		opacity: 1;
		transform: none;
	}
}

.pulseAnimation {
	display: inline-block;
	animation: pulse 2s infinite linear;
}

@keyframes pulse {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.spinAnimation {
	display: inline-block;
	animation: spin 800ms infinite linear;
	position: relative;
	left: -5px;
	top: 1px;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
