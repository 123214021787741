// Inputs are visible on BIA > PassAcc and other BIA pages. It is SVG graphic(SVG chart)
//----------------------------------------------------------------------------------------------------------------------
//
@import '../base/variables';

.InputBox {
	@include InputBox;
}

.DatePickerInput:read-only {
	background-color: white;
}
