@import '../base/variables';

.__react_component_tooltip {
	background-color: $passiveExtraLight !important;
	color: $passiveBlack !important;
	z-index: 1001 !important;
	padding: 0 !important;

	&.dontHideTooltip {
		pointer-events: auto !important;
	}

	&.type-info {
		.place-top:after {
			border-top: 6px solid $passiveExtraLight !important;
		}
		.place-bottom:after {
			border-bottom: 6px solid $passiveExtraLight !important;
		}
		.place-right:after {
			border-right: 6px solid $passiveExtraLight !important;
		}
		.place-left:after {
			border-left: 6px solid $passiveExtraLight !important;
		}
	}

	&.type-light {
		.place-bottom:before {
			transform: rotate(180deg) !important;
		}
		.place-left:after {
			border-left: 7px solid $light !important;
		}
	}

	&.tooltip-color-error {
		&.place-right:before {
			left: -8px !important;
			border-top-color: transparent !important;
			border-right-color: $error !important;
		}
		&.place-left:before {
			border-top-color: transparent !important;
			border-left-color: $error !important;
			right: auto !important;
			left: 105% !important;
		}
	}

	&.breadcrumbsTooltip {
		background-color: $light !important;
		border: 1px solid $passiveMedium;
		border-radius: 3px;
		padding: 0.25em !important;
		line-height: normal;
		font-family: font-reg, sans-serif;
		font-size: 14px;

		&.show {
			opacity: 1;
		}
	}

	&.type-dark {
		&.place-top:after {
			border-top-color: inherit;
		}

		&.place-right:after {
			border-right-color: inherit;
		}

		&.place-left:after {
			border-left-color: inherit;
		}
	}

	&.react-tooltip-cmp {
		background-color: $light !important;
		opacity: 1 !important;
		border-radius: 3px;
		box-shadow: 0 0 5px $passiveMedium;
		border: 1px solid $passiveMedium;
		pointer-events: auto;

		td,
		th {
			padding: 0 2px !important;
		}

		&.tiny-tooltip {
			box-shadow: 0 0 1px $passiveMedium;
		}

		&.tooltip-color-error {
			border: 1px solid $error;

			&::before {
				border-top-color: $error;
			}
		}

		&.tooltip-color-warning {
			border: 1px solid $warning;

			&::before {
				border-top-color: $warning;
			}
		}

		&::after {
			border-top-width: 7px;
			bottom: -6px !important;
		}

		&::before {
			content: '';
			width: 0;
			height: 0;
			position: absolute;
			border-top: 6px solid $passiveMedium;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			bottom: -7px !important;
			left: 50%;
			margin-left: -8px;
		}
	}

	h4 {
		color: $passiveBlack;
	}
}

.CMPTooltipIconWrapper {
	position: relative;
	top: 2px;
	display: inline-block;
	color: $dark;
}
