//
//----------------------------------------------------------------------------------------------------------------------
//
@import '../base/variables';

.FloatingLabel {
	position: absolute;
	transform: translateY(50%);
	padding-left: 15px;
	opacity: 0.3;
	cursor: text;
	transition: 0.25s;
	transition-timing-function: ease-in-out;
	line-height: 1.3;
}

.FloatingLabel--floated {
	top: 0;
	opacity: 1;
	transform: scale(0.9) translateX(-15px) translateY(-120%);
}
