@import '../base/variables';

*,
*::before,
*::after {
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
	height: 100% !important;
	position: relative;
}

body {
	font-family: font-reg, sans-serif !important;
	height: 100%;
	margin: 0;
	position: relative;
	width: 100%;
	font-size: 14px;
	background-color: $secondary;
}

//::-webkit-scrollbar {
//  width: 0px;  /* remove scrollbar space */
//  background: transparent;  /* optional: just make scrollbar invisible */
//}

#app {
	height: 100%;

	main {
		background-color: $light;
		padding-bottom: 90px;
		position: relative;
		min-height: 100vh;
	}
}

*:focus,
a:focus,
input:focus,
button:focus,
select:focus {
	outline: none;
}

input[disabled] {
	background-color: $passiveExtraLight !important;
	border-color: $passiveLight;
	&:hover {
		cursor: not-allowed;
	}
	&::-webkit-input-placeholder {
		color: $passiveLight;
	}
	&:-moz-placeholder {
		/* Firefox 18- */
		color: $passiveLight;
	}
	&::-moz-placeholder {
		/* Firefox 19+ */
		color: $passiveLight;
	}
	&:-ms-input-placeholder {
		color: $passiveLight;
	}
}

input[type='email']:focus,
input[type='password']:focus {
	outline-width: 2px;
}

input[type='search']:focus,
input[type='text']:focus {
	outline: 1px solid $primary;
}

label {
	font-weight: normal;
	color: $passiveMedium;
}

a {
	text-decoration: none;
	color: $primary;

	&:focus,
	&:active,
	&:hover {
		text-decoration: none;
	}
}

img {
	max-width: 100%;
	//height: auto;
}

ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

.file-move {
	max-height: 1000px;
	overflow-y: auto;
}

//placeholder colors
::-webkit-input-placeholder {
	/* Chrome */
	color: $borderPanelText;
	font-family: font-light, sans-serif;
}

:-ms-input-placeholder {
	/* IE 10+ */
	color: $borderPanelText;
	font-family: font-light, sans-serif;
}

::-moz-placeholder {
	/* Firefox 19+ */
	color: $borderPanelText;
	font-family: font-light, sans-serif;
	opacity: 1;
}

:-moz-placeholder {
	/* Firefox 4 - 18 */
	color: $borderPanelText;
	font-family: font-light, sans-serif;
	opacity: 1;
}

//hack for chrome autoComplete coloring of input
input[type='text']:-webkit-autofill,
input[type='text']:-webkit-autofill:hover,
input[type='text']:-webkit-autofill:focus,
input[type='text']:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 42px white inset !important;
}
