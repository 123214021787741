// Helpers for lists <li>
// NOTE: never use two helpers that can cancel each other
//----------------------------------------------------------------------------------------------------------------------
//
@import '../base/variables';

.h-list {
	list-style: inherit;
	list-style-position: outside;
	margin-left: 20px;
}

.h-sublist {
	list-style-type: circle;
	list-style-position: outside;
	margin-left: 20px;
}
.h-zebraStripes {
	> *:nth-child(even) {
		background-color: $passiveExtraLight;
	}
}
