@import '../base/variables';

.Datepicker__icon {
	position: absolute;
	left: auto;
	right: 15px;
	top: 9px;
	color: $passiveMedium;
	pointer-events: none;
	background: none;
}

.rdtPrev span,
.rdtNext span,
.rdtBtn {
	color: $primary;
}
