// Panels are present on all apps that follow "new" CMP design
//----------------------------------------------------------------------------------------------------------------------
//
@import '../base/variables';

main.landingPage,
main#cmp,
main#bia,
main#hvc,
main#pdm,
main#imp,
main.TableauApp,
main.admintableauapps,
main.admintableauappsmanagereports,
main.adminmanageidpproviderdetails {
	background-color: $passiveLighter !important;
}

main#depo {
	background-color: $passiveExtraLight !important;
	padding-bottom: 5px !important;
}

main#bia.faqs {
	background-color: $light;
}

.PanelGray {
	position: relative;
	margin: 0;
	width: 100%;
	background-color: $passiveExtraLight;
}

.PanelWhite {
	position: relative;
	padding: 25px 20px 25px;
	border: 1px solid $borderPanel;
	background-color: $light;
}
