@import '../base/variables';

.fontawesome-icon-list .fa-item {
	padding: 10px 20px;
	color: $passiveDark;
}

.fontawesome-icon-list .fa-item i {
	margin-right: 5px;
}

.fontawesome-icon-list .fa-item:hover {
	background-color: $passiveExtraLight;
}
