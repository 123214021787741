@import '../base/variables';

.TilesWrapper {
	margin-top: 50px;
}

.Tile {
	border: 1px solid $borderPanel;
	background-color: $light;
	position: relative;
	padding: 70px 15px 0 15px;
	margin-bottom: 100px;
}

.Tile__info {
	height: 120px;
	border-bottom: 1px solid $passiveSoft;
}

.Tile__footer {
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Tile__infoDescription {
	color: $passiveMiddle;
	font-size: 13px;
}

.Tile__icon {
	width: 104px;
	height: 104px;
	border-radius: 50%;
	position: absolute;
	top: -53px;
	left: 50%;
	transform: translateX(-50%);
	background-color: $light;
	z-index: 2;
}

.Tile__icon--default {
	background-size: 104px;
	background-position: center;
	background-repeat: no-repeat;
	width: 120px;
	height: 120px;
	top: -60px;
}

.Tile__icon--defaultDepo {
	background-image: url('images/svg/api.svg');
}

.Tile__icon--defaultIDP {
	background-image: url('images/svg/idp-default-icon.svg');
}

.Tile__button {
	margin: 10px;
	width: 120px;
}

.Tile__iconHalfCircleBorder {
	z-index: 1;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%, -100%);
	width: 122px;
	height: 61px;
	background-color: $light;
	border-top-left-radius: 67px;
	border-top-right-radius: 67px;
	border: 1px solid $borderPanel;
	border-bottom: 0;
}

.Tile--noIcon {
	padding-top: 20px;

	.Tile__info {
		height: 80px;
	}
}
