@import '../base/variables';


.BreadcrumbsWrapper{
	.breadcrumbsTooltip{
		border: 1px solid $primary !important;
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
		text-transform: capitalize;
	}
}

.Breadcrumbs {
	a,
	span {
		color: $primary;
		cursor: pointer;
	}

	.js-Breadcrumbs:last-child {
		font-weight: bold;
	}
	.Breadcrumbs__mobileName {
		display: none !important; // because of javascript, check Breadecrumbs.jsx
	}
	.js-Breadcrumbs{
		font-size: 20px;
		text-transform: capitalize;
	}
	 .js-Breadcrumbs:not(:first-child):before,.Breadcrumbs__ellipsis:before{
			 content: "";
			 font-weight: normal;
			 border-top: 2px solid $primary;
			 border-right: 2px solid $primary;
			 width: 7px;
			 height: 7px;
			 display: inline-block;
			 transform: rotate(45deg);
		 	 margin: 3px 5px;
	 }
}
