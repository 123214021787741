// AppLanding is visible on landing page, before you enter in some app
//----------------------------------------------------------------------------------------------------------------------
//
@import '../base/variables';

.AppLanding {
	margin-top: 33px;
	position: relative;
}

.AppLanding__icon {
	background-color: $light;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100px;
	width: 100% !important; //needed to overwrite inline styles from SVGIcon
	height: 194px !important; //needed to overwrite inline styles from SVGIcon
	@include BoxShadowAnimation(0, $primary);
}

.AppLanding__iconExternal {
	position: relative;

	&:before {
		content: '';
		display: inline-block;
		position: absolute;
		width: 20px;
		height: 20px;
		top: 8px;
		right: 8px;
		background: url('images/svg/linkout-2.svg') no-repeat;
		background-size: 20px 20px;
	}
}

.AppLanding__link {
	&:hover {
		.AppLanding__icon {
			@include BoxShadowAnimationHover(2px, $primary);
		}
	}
}

.AppLanding__label {
	color: $passiveMedium;
	white-space: pre-wrap;
	font-family: font-bold, sans-serif;
	font-weight: normal;
	font-size: 18px;
	padding-top: 13px;
	margin-bottom: 0;
	height: 70px;
}

.AppLanding__mailto {
	width: 100%;
}
