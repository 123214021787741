@import '../base/variables';

.ComboTable__topContent {
	margin-bottom: 20px;
}

.ComboTable__topContentFilter {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.ComboTable__search {
	background: $light url('images/svg/search.svg') no-repeat 10px center;
	background-size: 24px;
	outline: none !important;
	transition: unset;
	padding-left: 38px !important;
	line-height: 1.5;
	height: 42px;

	@media (min-width: 992px) {
		max-width: 180px;
	}
}

.ComboTable__exportButton {
	cursor: pointer;
	margin-left: 15px;
	color: $dark;

	&--disabled {
		color: $passiveSoft;
		cursor: not-allowed;
		pointer-events: none;
	}

	//hide export on small screens
	@media (max-width: $tabletPortrait) {
		display: none;
	}
}

.ComboTable__itemCount {
	margin-top: 10px;
	font-size: 14px;
}

.ComboTable__pagination {
	display: flex;
	justify-content: flex-end;
	padding: 20px 10px;
}

.ComboTable__pageButton {
	border: none;
	background-color: transparent;
	cursor: pointer;

	&:disabled {
		cursor: not-allowed;
		color: $passiveMiddle;
	}

	&--active {
		color: $primary;
	}
}
