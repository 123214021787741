// Check Boxes and Radio Buttons are visible on BIA > airline maintenance and other pages
//----------------------------------------------------------------------------------------------------------------------
//
@import '../base/variables';

//checkbox cmp styles for inputs inside .CheckBoxGroup class
.CheckBoxTitle {
	font-size: 15px;
	color: $passiveMedium;
	display: inline-block;
	margin-bottom: 10px;
}

.CheckBoxGroup {
	position: relative;
	line-height: 1.5;

	&--inlineFilters {
		display: inline-block;
		@media (max-width: 768px) {
			text-align: left;
			width: 100%;
			margin: 0;
		}
	}

	&--fileShare {
		width: 20px;
		height: 15px;
		margin-left: 10px;
		margin-right: 5px;
	}

	input[type='checkbox'],
	input[type='radio'] {
		opacity: 0;
		cursor: pointer;
	}

	label {
		cursor: pointer;
	}

	span {
		cursor: pointer;
	}

	.CheckBoxGroup__spanLabel {
		margin-left: 10px;
		margin-right: 10px;
	}

	//style checkBoxIcon and its :after only if there is input checkbox or radio sibling
	input[type='checkbox'] ~ .checkBoxIcon,
	input[type='radio'] ~ .checkBoxIcon {
		position: absolute;
		left: -2px;
		top: 2px;
		width: 17px;
		height: 17px;

		&:before {
			@include CheckBoxBg;
		}
	}

	//style checkBoxIcon and its :after only if input checkbox is disabled
	input[type='checkbox']:disabled,
	input[type='radio']:disabled {
		cursor: not-allowed;

		~ .checkBoxIcon {
			&:before {
				cursor: not-allowed;
				background-color: $passiveSoft;
			}
		}
	}

	//when checkbox is clicked show svg background
	input[type='checkbox']:checked ~ .checkBoxIcon:before {
		@include CheckBoxActiveIcon;
	}

	input[type='radio'] ~ .checkBoxIcon:before {
		border-radius: 50%;
	}
	//when radio is clicked show svg background
	input[type='radio']:checked ~ .checkBoxIcon:before {
		@include CheckBoxActiveIcon;
	}
}

.TableTopCheckboxFilters {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 42px;
	@media (max-width: 680px) {
		flex-direction: column;
	}
}
