// Helpers for padding
// NOTE: never use two helpers that can cancel each other
//----------------------------------------------------------------------------------------------------------------------
//

.h-paddingT--xxs {
	padding-top: 5px !important;
}

.h-paddingR--xxs {
	padding-right: 5px !important;
}

.h-paddingB--xxs {
	padding-bottom: 5px !important;
}

.h-paddingL--xxs {
	padding-left: 5px !important;
}

.h-paddingTB--xxs {
	padding-top: 5px !important;
	padding-bottom: 5px !important;
}

.h-paddingLR--xxs {
	padding-left: 5px !important;
	padding-right: 5px !important;
}

.h-paddingALL--xxs {
	padding: 5px !important;
}

// xs

.h-paddingT--xs {
	padding-top: 10px !important;
}

.h-paddingR--xs {
	padding-right: 10px !important;
}

.h-paddingB--xs {
	padding-bottom: 10px !important;
}

.h-paddingL--xs {
	padding-left: 10px !important;
}

.h-paddingTB--xs {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}

.h-paddingLR--xs {
	padding-left: 10px !important;
	padding-right: 10px !important;
}

.h-paddingALL--xs {
	padding: 10px !important;
}

// sm

.h-paddingT--sm {
	padding-top: 15px !important;
}

.h-paddingR--sm {
	padding-right: 15px !important;
}

.h-paddingB--sm {
	padding-bottom: 15px !important;
}

.h-paddingL--sm {
	padding-left: 15px !important;
}

.h-paddingTB--sm {
	padding-top: 15px !important;
	padding-bottom: 15px !important;
}

.h-paddingLR--sm {
	padding-left: 15px !important;
	padding-right: 15px !important;
}

.h-paddingALL--sm {
	padding: 15px !important;
}

// md

.h-paddingT--md {
	padding-top: 20px !important;
}

.h-paddingR--md {
	padding-right: 20px !important;
}

.h-paddingB--md {
	padding-bottom: 20px !important;
}

.h-paddingL--md {
	padding-left: 20px !important;
}

.h-paddingTB--md {
	padding-top: 20px !important;
	padding-bottom: 20px !important;
}

.h-paddingLR--md {
	padding-left: 20px !important;
	padding-right: 20px !important;
}

.h-paddingALL--md {
	padding: 20px !important;
}

// lg

.h-paddingT--lg {
	padding-top: 25px !important;
}

.h-paddingR--lg {
	padding-right: 25px !important;
}

.h-paddingB--lg {
	padding-bottom: 25px !important;
}

.h-paddingL--lg {
	padding-left: 25px !important;
}

.h-paddingTB--lg {
	padding-top: 25px !important;
	padding-bottom: 25px !important;
}

.h-paddingLR--lg {
	padding-left: 25px !important;
	padding-right: 25px !important;
}

.h-paddingALL--lg {
	padding: 25px !important;
}

// xxl

.h-paddingT--xxl {
	padding-top: 30px !important;
}

.h-paddingR--xxl {
	padding-right: 30px !important;
}

.h-paddingB--xxl {
	padding-bottom: 30px !important;
}

.h-paddingL--xxl {
	padding-left: 30px !important;
}

.h-paddingTB--xxl {
	padding-top: 30px !important;
	padding-bottom: 30px !important;
}

.h-paddingLR--xxl {
	padding-left: 30px !important;
	padding-right: 30px !important;
}

.h-paddingALL--xxl {
	padding: 30px !important;
}

.h-noPadding {
	padding: 0 !important;
}

.h-noPaddingRight {
	padding-right: 0 !important;
}

.h-paddingB--push {
	padding-bottom: 60px !important;
}

.h-paddingR--push {
	padding-right: 50px !important;
}
