// Thumbnail is visible on BIA > Custom report page
//----------------------------------------------------------------------------------------------------------------------
//
@import '../base/variables';

.Thumbnail {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
	padding: 4px;
	margin-bottom: 20px;
	line-height: 1.4;
	background-color: $light;
	border: 1px solid $borderPanel;
	transition: border 0.2s ease-in-out;
	border-radius: 2px;
}

.Thumbnail__image {
	display: block;
	height: 200px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 25px;
	max-width: 100%;

  &--tblReportDefault {
    width: 100%;
    background: url('images/svg/report-default-image1.svg') no-repeat center center;
  }
}

.Thumbnail__caption {
	padding: 9px;
	color: $passiveBlack;
}
