// combo-select CMP like design (for now only on cmp page, bia page, ProfileMenu(top right menu)
//------------------------------------------------------------
//
@import '../base/variables';

.ProfileMenu {
	.ProfileMenu__airline {
		.combo-select-head {
			width: 100%;
		}

		.combo-select-group__item {
			max-height: 30px;
			margin: 5px 0 5px 20px;
			padding: 0 !important;
		}
	}
}

.ShortCombo {
	.combo-select {
		width: 40%;
	}
}

.ComboStyleOverride {
	.combo-select {
		min-width: 180px;

		.combo-select-body {
			border-radius: 2px;
			font-size: 14px;
			border-color: $borderPanel;

			.combo-select-item {
				white-space: nowrap;
			}

			.combo-select-item.selected path {
				fill: $passiveMedium;
			}
		}

		path {
			fill: $passiveMedium;
		}

		.search-input {
			border-radius: 2px;
			border: 1px solid $borderPanel !important;
			color: $dark;
		}
	}

	.combo-select-head {
		border: 1px solid $borderPanel;
		border-radius: 2px;
		font-size: 14px;
		line-height: 2.6;
		padding-left: 19px;
		color: $passiveMedium;
		height: 42px;
		width: auto;
		min-width: 180px;
		cursor: pointer;
		vertical-align: top;
		overflow: hidden;
		padding-right: 35px;

		@media (max-width: 640px) {
			width: 100%;
		}
	}
}
