// Modal global styles (applicable to all modals)
//----------------------------------------------------------------------------------------------------------------------
//
@import '../base/variables';

.ReactComboModalHolder {
	max-width: 500px;
}

.ReactComboModal {
	position: relative;
	padding: 20px;
	overflow: auto;
}

.ReactComboModal--narrow {
	width: auto;
	min-width: 220px;
}

.ReactComboModal--withNotifications {
	overflow: hidden;
	padding: 0;
}
.ReactComboModal--transparentBg {
	background: transparent;
}

.ReactComboModal__scrollableContent {
	max-height: 70vh;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 0 20px 20px 20px;
	background: $light;
}

.ReactComboModal__fixedTitle {
	padding: 5px;
	margin-left: 10px;
	margin-right: 10px;
}

.ReactComboModal__notificationBox {
	padding: 13px;
	text-align: center;
	color: $light;
	width: 100%;
}

.ReactComboModal__notificationBox--error {
	background: $error;
}

.ReactComboModal__notificationBox--success {
	background: $success;
}

.ReactComboModal__noPadding {
	padding: 0;
}