// Helpers for margins
// NOTE: never use two helpers that can cancel each other
//----------------------------------------------------------------------------------------------------------------------
//
@import '../base/variables';

.h-marginT--xxs {
	margin-top: 5px !important;
}

.h-marginR--xxs {
	margin-right: 5px !important;
}

.h-marginB--xxs {
	margin-bottom: 5px !important;
}

.h-marginL--xxs {
	margin-left: 5px !important;
}

.h-marginT--xs {
	margin-top: 10px !important;
}

.h-marginR--xs {
	margin-right: 10px !important;
}

.h-marginB--xs {
	margin-bottom: 10px !important;
}

.h-marginL--xs {
	margin-left: 10px !important;
}

.h-marginT--sm {
	margin-top: 15px !important;
}

.h-marginR--sm {
	margin-right: 15px !important;
}

.h-marginB--sm {
	margin-bottom: 15px !important;
}

.h-marginL--sm {
	margin-left: 15px !important;
}

.h-marginT--md {
	margin-top: 20px !important;
}

.h-marginR--md {
	margin-right: 20px !important;
}

.h-marginB--md {
	margin-bottom: 20px !important;
}

.h-marginL--md {
	margin-left: 20px !important;
}

.h-marginALL--md {
	margin: 20px !important;
}

.h-marginT--lg {
	margin-top: 25px !important;
}

.h-marginTabletT--xs {
	@media (max-width: $tabletPortrait) {
		margin-top: 10px !important;
	}
}

.h-marginTabletT--lg {
	@media (max-width: $tabletPortrait) {
		margin-top: 25px !important;
	}
}

.h-marginR--lg {
	margin-right: 25px !important;
}

.h-marginB--lg {
	margin-bottom: 25px !important;
}

.h-marginL--lg {
	margin-left: 25px !important;
}

.h-marginT--xxl {
	margin-top: 30px !important;
}

.h-marginR--xxl {
	margin-right: 30px !important;
}

.h-marginB--xxl {
	margin-bottom: 30px !important;
}

.h-marginL--xxl {
	margin-left: 30px !important;
}

.h-marginT--xxxl {
	margin-top: 35px !important;
}

.h-noMargin {
	margin: 0 !important;
}
.h-noMarginT {
	margin-top: 0 !important;
}
.h-noMarginB {
	margin-bottom: 0 !important;
}
.h-noMarginTablet {
	@media (max-width: $tabletPortrait) {
		margin: 0 !important;
	}
}
