// AppLanding is visible on landing page, before you enter in some app
//----------------------------------------------------------------------------------------------------------------------
//
@import '../base/variables';

.AppSwitcher__wrapper {
	width: 116px;
	height: 116px;
	margin: 0 0 18px 16px;
	float: left;
	padding: 5px;
	position: relative;
	color: $light;
	@include BoxShadowAnimation(0, $primary);

	&.active,
	&:hover {
		background-color: $passiveDark;
		color: $primary;
		@include BoxShadowAnimationHover(1px, $primary);

		.AppSwitcher__iconExternal::before {
			background-image: url('images/svg/linkout-2.svg');
		}
	}
}

.AppSwitcher__wrapperLink {
	display: inline-block;
	width: 100%;
	color: inherit;
}

.AppSwitcher__icon {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	margin-top: 14px;
	height: 45px !important; //needed to overwrite inline styles from SVGIcon
	width: 100% !important; //needed to overwrite inline styles from SVGIcon
	color: inherit;
}

.AppSwitcher__iconExternal {
	position: relative;

	&:before {
		content: '';
		display: inline-block;
		position: absolute;
		width: 16px;
		height: 16px;
		top: -10px;
		right: 3px;
		background-image: url('images/svg/linkout.svg');
		background-repeat: no-repeat;
		background-size: 16px;
	}
}

.AppSwitcher__iconLabel {
	color: inherit;
	font-family: font-bold, sans-serif;
	font-weight: 400;
	font-size: 14px;
	padding-top: 6px;
	height: 54px;
}
