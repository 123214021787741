@import "../base/variables";

.ManageTableauApps__appHeader {
  background-color: $passiveExtraLight;
}

.ManageTableauApps__appHeading {
  margin: 15px 0 5px 0;
}

.ManageTableauApps__appTile {
  height: 340px;

  .Button--small--passive {
    width: 100px;
    margin-top: 10px;
  }
}

.ManageTableauApps__appTilesLoader {
  font-size: 30px;
}

.ManageTableauApps__appPreview {
  position: relative;
  padding: 10px;
  display: inline-block;
  width: 240px;
  height: 194px;
  background-color: $light;
}

.ManageTableauApps__iconUploadError {
  color: $alternative;
}

.ManageTableauApps__iconUploadPreview {
  .ManageTableauApps__iconUploadPreviewImage {
    max-width: 30%;

    &--appDefault {
      display: inline-block;
      height: 134px;
      width: 100%;
      background: url('images/app_switcher/tbl-icon-app-2.svg') no-repeat center center;
    }

    &--reportDefault {
      display: inline-block;
      height: 134px;
      width: 100%;
      background: url('images/svg/report-default-image1.svg') no-repeat center center;
    }
  }

}

.ManageTableauApps__linkButton {
  line-height: 21px !important;
}

.ManageTableauApps__appIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 174px;
  max-width: 100px;

  &--default {
    display: inline-block;
    height: 100px;
    width: 100%;
    background: url('images/app_switcher/tbl-icon-app-2.svg') no-repeat center center;
  }
}

.TableaApp__separator {
  height: 60px;
  background-color: $light;
}