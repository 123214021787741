//
// Third party components css
@import '~react-combo-select/style.css';
@import '~react-combo-modal/style.css';
@import '~react-combo-search/css/style.css';
@import '~react-slide-out/lib/index.css';
@import '~react-text-2-list/css/style.css';
@import '~react-table/react-table.css';
@import '~react-collapsy/lib/index.css';

//
// Base (shared across all ggs components)
@import './sass/base/reset';
@import './sass/base/reset-bootstrap';
@import './sass/base/fontawesome';
@import './sass/base/_bootstrap-3-grid.css';
@import './sass/base/variables';
@import './sass/base/mixins';
@import './sass/base/typography';
@import './sass/components/svg-icon'; //need to have lower specificity

//
// styles that are shared across all ggs components
@import './sass/shared-styles/buttons';
@import './sass/shared-styles/checkbox';
@import './sass/shared-styles/inputs';
@import './sass/shared-styles/modal';
@import './sass/shared-styles/overview';
@import './sass/shared-styles/theme-panel';
//
// Components that are 3rd party plugins and their overwrites
@import './sass/components-vendor/reactComboModal-overwrite';
@import './sass/components-vendor/combo-select-overwrite';
@import './sass/components-vendor/dropzone-overwrite';
@import './sass/components-vendor/formsy-overwrite';
@import './sass/components-vendor/formsy-radio-overwrite';
@import './sass/components-vendor/datatables-overwrite';
@import './sass/components-vendor/tooltip-overwrite';
@import './sass/components-vendor/flight-map-overlay-overwrite';
@import './sass/components-vendor/react-table-overwrite';
//
// Components that live in shared components app
@import './sass/components/app-header';
@import './sass/components/breadcrumbs';
@import './sass/components/copyButton';
@import './sass/components/datepicker';
@import './sass/components/file-share-by-link-form';
@import './sass/components/formsyFloatingLabelInput';
@import './sass/components/overviewInfoBox';
@import './sass/components/partners';
@import './sass/components/simpleStats';
@import './sass/components/manage-tableau-apps';
@import './sass/components/enable-disable';
@import './sass/components/tile';
@import './sass/components/combo-table';
@import './sass/components/reportListItem';
@import './sass/components/ldapUserStyles';
@import './sass/components/appIcons-Switcher';
@import './sass/components/appIcons-Landing';
@import './sass/components/errorNotification';
@import './sass/components/password-strength';
@import './sass/components/header-user';

//
// helpers (shared across all ggs components)
@import './sass/helpers/margins';
@import './sass/helpers/paddings';
@import './sass/helpers/lists';
@import './sass/helpers/text';
@import './sass/helpers/borders';
@import './sass/helpers/colors';
@import './sass/helpers/layout';
@import './sass/helpers/animations';
