.SvgIconWrapper {
	margin: 0;
	padding: 0;
	position: relative;
	top: 0;
	left: 0;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
    line-height: 0;
}
