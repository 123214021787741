// Modal global styles (applicable to all modals)
//----------------------------------------------------------------------------------------------------------------------
//
@import "../base/variables";

.Modal__footer {
	width: 100%;
	padding-top: 15px;
}

.Modal__listItem {
	background: $passiveLighter;
	padding: 10px;
	margin-bottom: 10px;
}

.Modal__listItem--error {
	background: $alternative;
	color: $light;
	margin-top: 10px;
}

.Modal__listItemIcon {
	position: relative;
	top: 3px;
}

.Modal__scrollableList {
	overflow: auto;
	width: 100%;
	max-height: 450px;
	margin-bottom: 15px;

	@media (max-width: $mobile) {
		max-height: 190px;
	}
}

.Modal__message {
	padding: 13px;
	text-align: center;
	color: $light;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	transform: translateY(100%);
}

.Modal__message--success {
	background: $success;
}
