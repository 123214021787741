// OverviewInfo is shared-component placed on top of BIA > Maintenance, BIA > PassAcc, BIA > Performance, HVC > PartnerPage, Admin app ...
//----------------------------------------------------------------------------------------------------------------------
//
@import "../base/variables";

.Overview {
	.Overview__text {
		float: left;
		margin-left: 2.29%;
		margin-right: 3.96%;
		font-family: font-reg, sans-serif;
		font-weight: normal;
		font-size: 20px;
		white-space: nowrap;

		@media (max-width: 1233px) {
			width: 27%;
		}
	}

	.Overview__text .Overview__text--counts {
		@media (max-width: 500px) {
			width: 100%;
		}
	}
}
