// mixins
//----------------------------------------------------------------------------------------------------------------------
//
@import '../base/variables';

@mixin h-clearfix() {
	&:before,
	&:after {
		content: '';
		display: table;
	}
	&:after {
		clear: both;
	}
}

@mixin InputBox {
	padding: 11px 15px;
	border: 1px solid $borderPanel;
	width: 100%;
	display: block;
	outline: 1px solid transparent;
	transition: all 0.2s ease-in-out;
	color: $dark;

	&:focus {
		outline: 1px solid $primary;
	}
}

@mixin CheckBoxBg {
	content: '';
	display: inline-block;
	width: 17px;
	height: 17px;
	position: absolute;
	top: 0;
	left: 0;
	background-repeat: no-repeat;
	background-position: center;
	background-size: auto;
	background-color: $light;
	border: 1px solid $passiveMedium;
}

@mixin CheckBoxActiveIcon {
	background-color: $passiveMedium;
	background-image: url('images/svg/check-mark-sign-white-ico.svg');
}

@mixin BoxShadowAnimation($shadowWidth,$shadowColor) {
	box-shadow: 0 0 0 $shadowWidth $shadowColor;
	transition: all 0.1s linear;
}

@mixin BoxShadowAnimationHover($shadowWidth, $shadowColor) {
	box-shadow: 0 0 0 $shadowWidth $shadowColor;
}
