//
//----------------------------------------------------------------------------------------------------------------------
//
@import '../base/variables';

.copyButtonIco {
	position: absolute;
	right: 8px;
	bottom: 9px;
	background: none;
	border: none;

	&:hover {
		transform: scale(1.1);
	}

	&:disabled {
		color: $passiveMiddle;
		cursor: not-allowed;

		&:hover {
			transform: scale(1);
		}
	}
}
