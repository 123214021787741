@import '../base/variables';

.Tabs__header {
	display: flex;
	flex: 1;
}

.Tabs__headerItem {
	height: 55px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-right: 15px;
	padding: 0 10px;
	min-width: 100px;
	border-bottom: 2px solid transparent;
	transition: all 0.2s;

	&--active {
		font-family: font-bold, sans-serif;
		border-bottom: 2px solid $primary;
	}
}

.Tabs__body {
	height: calc(100vh - 220px);
	border-radius: 2px;
	background-color: $light;
	padding: 25px 40px;
	overflow: auto;

	@media (max-width: 700px) {
		height: calc(100vh - 320px);
	}
}

.Tabs__error {
	color: $error;
}

.ComboTable__topContent {
	margin-bottom: 0;
	padding: 10px;

	&.row {
		@media (min-width: 992px) {
			display: flex;
			align-items: center;
		}
	}

	& .combo-select {
		text-align: center;
		width: 30%;
		position: relative;
		z-index: 10;

		@media (max-width: 1000px) {
			width: 100%;
			transform: translateX(0);
			margin: 10px 0 10px 0;
		}
	}
}

.Tabs__wrapper {
	display: flex;
	align-items: center;
	border-bottom: 1px solid $borderPanel;
}

.StatsWrapper {
	padding: 0 15px 15px 15px;

	& span {
		padding-left: 2em;
		font-size: 90%;
	}
    & .role__name {
      display: inline-block;
      width: 20rem;
    }
}
