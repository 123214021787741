// Helpers for borders
// NOTE: never use two helpers that can cancel each other
//----------------------------------------------------------------------------------------------------------------------
//
@import '../base/variables';

.h-borderT {
	border-top: 1px solid $borderPanel;
}

.h-borderB {
	border-bottom: 1px solid $borderPanel;
}

.h-borderDarkB {
	border-bottom: 1px solid $passiveMedium;
}

.h-borderTB {
	border-top: 1px solid $borderPanel;
	border-bottom: 1px solid $borderPanel;
}

.h-borderL {
	border-left: 1px solid $borderPanel;
}

.h-borderR {
	border-right: 1px solid $borderPanel;
}

.h-borderLR {
	border-left: 1px solid $borderPanel;
	border-right: 1px solid $borderPanel;
}

.h-borderALL {
	border: 1px solid $borderPanel;
}

.h-noBorder {
	border: none !important;
}
