@import '../base/variables';

.dropzone {
	position: relative;
	padding: 20px;
	margin-bottom: 20px;
	color: #777;
	text-align: center;
	border: 2px dashed $passiveMiddle;
	border-radius: 4px;
	cursor: pointer;
}

.dropzone--custom {
	padding: 10px;

	& p {
		padding: 0;
		margin: 0;
	}

	div.dropzone {
		transition: background 0.25s;
	}

	div.dropzone:hover {
		background: $passiveLighter;
	}
}
