// Buttons styles for all apps
//----------------------------------------------------------------------------------------------------------------------
//
@import '../base/variables';
//todo: create mixing for .Button modifiers
//todo: remove unnecessary properties

.Button {
	display: inline-block;
	height: 42px;
	font-family: font-bold, sans-serif;
	font-size: 14px;
	border-radius: 2px;
	border-width: 0 0 2px;
	border-style: solid;
	min-width: 100px;
	transition: background-color 0.4s;
	padding: 2px 8px;
	text-align: center;
	z-index: 5;
	margin-top: 0;

	&.Button--text {
		font-family: font-light, sans-serif;
		border-color: transparent;
		background-color: transparent;

		&:disabled {
			cursor: not-allowed;
			color: $passiveMiddle;
		}
	}
	&.Button--text--underline {
		font-family: font-bold, sans-serif;
		text-decoration: underline;
		color: $secondary;
	}

	&.Button--logOutMenu {
		position: relative;
		width: 100%;
		height: 62px;
		margin: 0 !important;
		background: transparent;
		border-width: 1px 0 0;

		&:focus,
		&:hover {
			background-color: $passiveDark;
			border-color: $primary;
			color: $primary;
		}

		.SvgIconWrapper {
			position: absolute;
			width: 20px;
			height: 20px;
			font-size: 19px;
			left: 15px;
			top: 50%;
			margin-top: -10px;
		}
	}

	&.Button--action {
		color: $buttonTextColor;
		background-color: $primary;
		border-color: $primaryDark;
		min-width: 145px;

		&:focus,
		&:hover {
			background-color: $primaryLight;
		}

		&:disabled {
			color: $buttonTextColor;
			background-color: $passiveSoft;
			border-color: $passiveDark;
			transition: background-color 0.4s;
			cursor: not-allowed;
		}
	}

	&.Button--action--alternative {
		background-color: $primary;
		border-color: $primaryDark;

		&:focus,
		&:hover {
			background-color: $primaryLight;
		}

		&:disabled {
			background-color: $primaryLight;
			border-color: $primaryDark;
			cursor: not-allowed;
		}
	}

	&.Button--action--white {
		background-color: $light;
		border-color: $light;
		color: $primaryDark;

		&:focus,
		&:hover {
			background-color: $light;
		}
	}

	&.Button--passive {
		color: $light;
		background-color: $passiveMedium;
		border-color: $dark;

		&:focus,
		&:hover {
			background-color: $passiveLight;
		}

		&--modal {
			min-width: 145px;
		}

		&:disabled {
			background-color: $passiveSoft;
			cursor: not-allowed;
		}
	}

	&.Button--secondary {
		color: $light;
		background-color: $secondary;
		border-color: $dark;

		&:focus,
		&:hover {
			background-color: $passiveMedium;
		}

		&:disabled {
			background-color: $passiveSoft;
			cursor: not-allowed;
		}
	}

	&.Button--small {
		background-color: $primary;
		border-color: $primaryDark;
		height: 25px;
		font-size: 12px;
		line-height: 1.9;
		color: $buttonTextColor;
		border: none;

		&:focus,
		&:hover {
			background-color: $primaryLight;
		}

		&:disabled {
			color: $buttonTextColor;
			background-color: $passiveSoft;
			border-color: $passiveDark;
			transition: background-color 0.4s;
			cursor: not-allowed;
		}
	}

	&.Button--small--passive {
		background-color: $passiveSoft;
		color: $passiveBlack;
		min-width: 70px;

		&:focus,
		&:hover {
			background-color: $passiveMedium;
			color: $light;
		}

		&:disabled {
			color: $light;
			background-color: $passiveSoft;
			border-color: $passiveDark;
			transition: background-color 0.4s;
			cursor: not-allowed;
		}
	}

	&.Button--pill {
		font-size: 12px;
		min-width: 60px;
		border-radius: 18px;
		height: 30px;
		background-color: $light;
		border-width: 1px;
		border-style: solid;
		border-color: $passiveLighter;
		margin-right: 5px;
		margin-bottom: 5px;
		padding: 0 12px;
		line-height: 1;
		color: $passiveLight;
		font-family: font-reg, sans-serif;

		&:focus,
		&:hover {
			background-color: $passiveDark;
		}

		&.active {
			background-color: $passiveDark;
			border-color: $dark;
			color: white;
		}
	}

	&.Button--tab {
		background-color: $light;
		border-radius: 0;
		border-width: 1px;
		border-color: $passiveLighter;
		color: $passiveLight;
		font-family: font-reg, sans-serif;

		&:focus,
		&:hover {
			background-color: $passiveDark;
		}

		&.active {
			font-family: font-bold, sans-serif;
			background-color: $passiveDark;
			border-color: $dark;
			color: white;
		}
	}

	&.Button--tableActions {
		min-width: 42px;
		background-color: $passiveMedium;
		font-size: 17px;
		color: $light;
		border-color: $passiveDark;
		margin-right: 5px;

		&:focus,
		&:hover {
			background-color: $passiveSoft;
		}

		i {
			color: $light !important;
		}
	}
}

.ApplyResetButtons {
	float: right;
	@media (min-width: $tabletLandscape) {
		min-width: 225px;
	}

	.Button {
		min-width: 180px;
		padding: 0;
	}

	@media (max-width: 350px) {
		.Button {
			margin-left: 5%;
		}
	}
}

// ApplyResetButtons Overrides BIA > PassengerActivity
.PassengerActivity__global .ApplyResetButtons {
	@media (max-width: 350px) {
		width: 100%;
	}
}

// ApplyResetButtons Overrides BIA > Aircraft page
.AircraftPage {
	.ApplyResetButtons {
		margin-top: 22px;
	}
}

.ButtonClose {
	position: absolute;
	right: 15px;
	left: auto;
	top: 15px;
	z-index: 10;
	cursor: pointer;
	color: $dark;
}

// table and charts export button
.ExportButton {
	float: right;
	cursor: pointer;
	margin-top: 7px;
	margin-left: 15px;
	color: $dark;

	@media (max-width: $phablet) {
		display: none;
	}

	&--disabled {
		color: $passiveSoft;
		pointer-events: none;
	}
}
