@import '../base/variables';

//Don't remove those rules, they are needed for export table buttons
.dt-buttons {
	position: relative;
	float: left;
}

.dt-buttons.buttons-right {
	float: right;
}

.sticky {
	position: -webkit-sticky !important;
	position: sticky !important;
	z-index: 2;
}

.sticky:before,
.sticky:after {
	content: '';
	display: table;
}

@media screen and (max-width: 640px) {
	.dt-buttons {
		float: none !important;
		text-align: center;
	}
}

.dt-button-collection {
	position: absolute;
	top: 0;
	left: 0;
	width: 150px;
	margin-top: 3px;
	padding: 8px 8px 4px 8px;
	border: 1px solid $borderPanel;
	background-color: $light;
	overflow: hidden;
	z-index: 2002;
	border-radius: 5px;
	box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
	z-index: 2002;
	column-gap: 8px;
}

.dt-button-collection button.dt-button,
.dt-button-collection .dt-button,
.dt-button-collection a.dt-button {
	position: relative;
	left: 0;
	right: 0;
	display: block;
	float: none;
	margin-bottom: 4px;
	margin-right: 0;
}

.dt-button-collection button.dt-button:active:not(.disabled),
.dt-button-collection button.dt-button.active:not(.disabled),
.dt-button-collection .dt-button:active:not(.disabled),
.dt-button-collection .dt-button.active:not(.disabled),
.dt-button-collection a.dt-button:active:not(.disabled),
.dt-button-collection a.dt-button.active:not(.disabled) {
	background-color: $passiveLight;
	box-shadow: inset 1px 1px 3px $passiveMedium;
}

.dt-button-collection.fixed {
	position: fixed;
	top: 50%;
	left: 50%;
	margin-left: -75px;
}

.dt-button-collection.fixed.two-column {
	margin-left: -150px;
}

.dt-button-collection.fixed.three-column {
	margin-left: -225px;
}

.dt-button-collection.fixed.four-column {
	margin-left: -300px;
}

.dt-button-collection > * {
	-webkit-column-break-inside: avoid;
	break-inside: avoid;
}

.dt-button-collection.two-column {
	width: 300px;
	padding-bottom: 1px;
	column-count: 2;
}

.dt-button-collection.three-column {
	width: 450px;
	padding-bottom: 1px;
	column-count: 3;
}

.dt-button-collection.four-column {
	width: 600px;
	padding-bottom: 1px;
	column-count: 4;
}

// (END)Don't remove those rules, they are needed for export table buttons

//Don't remove this rule, it needs box-sizing: content-box;
table.dataTable td,
table.dataTable th {
	box-sizing: content-box;
}

// (END)Don't remove this rule, it needs box-sizing: content-box;

.DataTable {
	width: 100%;
	max-width: 100%;
	margin-top: 0 !important;

	.DataTable__label {
		background-color: $passiveLight;

		&:hover {
			background-color: $DataTableActiveRow;
			color: $dark;
		}

		span {
			margin-left: 5px;
		}

		i {
			cursor: pointer;
			padding: 0 3px;
		}
	}

	th:after {
		color: $light;
		opacity: 1;
	}

	thead th,
	thead td,
	tbody th,
	tbody td,
	tfoot th,
	tfoot td {
		padding: 8px;
		line-height: 1.42857143;
		vertical-align: top;
		border-top: 1px solid $borderPanel;
		white-space: nowrap;
	}

	thead th {
		border-bottom: 1px solid $passiveMedium;
		color: white;
		background-color: $passiveMedium;
		text-align: left;

		&:last-child {
			border-right: none;
		}
	}

	//sorting icons in table header
	thead > tr > th {
		font-family: font-light, sans-serif;

		&.sorting,
		&.sorting_desc,
		&.sorting_asc,
		&.sorting_disabled {
			position: relative;
			padding: 6px 30px 7px 15px;
			vertical-align: middle;
			cursor: pointer;
			overflow: hidden;
		}
		&.sorting_disabled {
			cursor: default;
		}

		&.sorting:after,
		&.sorting_desc:after,
		&.sorting_asc:after,
		&.sorting_disabled :after,
		&.sorting_asc_disabled:after,
		&.sorting_desc_disabled:after {
			display: block;
			width: 17px;
			height: 17px;
			position: absolute;
			right: 8px;
			top: 50%;
			margin-top: -9px;
			content: '';
		}

		&.sorting:after {
			/* sort (arrow-sort-ico.svg) */
			background: url('images/svg/arrow-sort-ico.svg');
		}

		&.sorting_asc:after {
			/* sort-by-attributes (arrow-sort-down-ico.svg) */
			background: url('images/svg/arrow-sort-down-ico.svg');
		}

		&.sorting_desc:after {
			/* sort-by-attributes-alt (arrow-sort-up-ico.svg) */
			background: url('images/svg/arrow-sort-up-ico.svg');
		}

		&.DataTable__paddingAll--xs {
			padding: 10px;
		}
	}

	tbody td {
		border-color: $passiveLighter !important;
		padding: 7px 15px;
	}

	tbody td i {
		vertical-align: initial;
	}

	tbody .transaction-denied > td {
		background-color: $alternativeDenied;
	}

	tbody .highlight--table--row td {
		background-color: #c3efd1;
	}

	tbody .transaction-denied td.jsFlightLink a {
		color: $secondary;
	}

	//row styles odd in .odd table
	.odd {
		background-color: $light;

		&:hover {
			background-color: $DataTableActiveRow;
		}
	}
	//row styles odd in no css class table
	tr:not(.odd):not(.even):nth-child(odd) {
		background-color: $light;

		&:hover {
			background-color: $DataTableActiveRow;
		}
	}
	//row styles even in .even table
	.even {
		background-color: $passiveExtraLight;

		&:hover {
			background-color: $DataTableActiveRow;
		}
	}
	//row styles even in no css class table
	tr:not(.even):not(.odd):nth-child(even) {
		background-color: $passiveExtraLight;

		&:hover {
			background-color: $DataTableActiveRow;
		}
	}

	.DataTable__rowSelected {
		background-color: $DataTableActiveRow;
	}

	&.DataTable--bordered {
		border: 0;

		thead th,
		thead td,
		tbody th,
		tbody td,
		tfoot th,
		tfoot td {
			border: 1px solid $passiveLighter;
		}

		tbody td {
			border-top-width: 0;
		}

		thead th,
		tbody tr.even td,
		tbody tr.odd td {
			text-align: center;
		}

		tbody tr td {
			&.DataTable__textBolder {
				font-family: font-bold, sans-serif;
				font-size: 15px;
			}
			//[ADP-3698] [ADP-3507] use this two css classes if we need to show only first 20 characters for email address on CMP Usage table
			&.DataTable__textEclipis.specificityOverwrite {
				text-overflow: ellipsis;
				overflow: hidden;
				max-width: 185px;
				box-sizing: border-box;
				text-align: left;
			}
		}
	}

	&.DataTable--framed {
		thead {
			border-top: 1px solid $passiveMedium;
		}

		//thead tr th:first-of-type,
		//tbody tr th:last-of-type {
		//	border-left: 1px solid $passiveMedium;
		//}
		//
		//tbody tr td:first-of-type,
		//tbody tr td:last-of-type {
		//	border-left: 1px solid $passiveMedium;
		//}
		//
		//tbody tr td {
		//	border-right: 1px solid $passiveMedium;
		//}

		tbody tr:last-of-type td {
			border-bottom: 1px solid $passiveMedium;
		}
	}

	.DataTable__details {
		td {
			border-bottom: none !important;
			text-align: left;
		}

		//get first next tr (after .even)
		&.even + tr {
			background-color: $passiveExtraLight !important;
		}
		//get first next tr (after .odd)
		&.odd + tr {
			background-color: $light !important;
		}

		//expanded row (on click) details inside table
		//todo: add .class for this inside component ???
		& + tr {
			color: $passiveDark;

			tr {
				background-color: transparent !important;

				//every cell
				th,
				td {
					position: relative;
					border: none;
					padding: 0 15px 4px 15px;

					button {
						min-width: 130px;
					}
				}

				//heading (first cell)
				th {
					padding: 2px 15px 15px 15px;
					font-size: 16px;
					background: inherit;
					border: none;
				}
			}

			//whole expanded table (keep it below every cell)
			> td {
				padding-bottom: 20px;
			}

			th,
			strong {
				color: $passiveDark;
				font-family: font-bold, sans-serif;
				margin-right: 5px;
			}
		}
	}

	.DataTable__detailsControl {
		cursor: pointer;

		//add this class on <td> if only icon for expand row is needed
		&.DataTable__detailsControl--onlyIcon {
			&:after {
				width: 100%;
				margin: auto;
			}
		}
	}
	//closed expand row icon
	.DataTable__detailsControl:not(th) {
		&:after {
			display: inline-block;
			content: '';
			width: 15px;
			height: 15px;
			margin-left: 15px;
			overflow: hidden;
			background-image: url('images/svg/arrow-row-expand-down-ico.svg');
			transition: 200ms transform;
			background-size: 15px;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	//open expanded row icon
	.DataTable__details .DataTable__detailsControl {
		&:after {
			transform: rotate(180deg);
		}
	}
}

.SmartTableWrap--clickable {
	thead tr,
	tbody tr,
	tfoot tr {
		cursor: pointer;

		&:hover {
			background-color: $DataTableActiveRow !important;
		}
	}
}

// DataTable classes
.dataTables_wrapper {
	*zoom: 1;

	&:before,
	&:after {
		content: '';
		display: table;
	}

	&:after {
		clear: both;
	}

	.dataTables_filter {
		float: right;
		padding-right: 0;

		@media (max-width: $tabletLandscape) {
			width: 100%;
			clear: both;
		}

		label {
			width: 100%;
			margin-bottom: 20px;

			@media (max-width: $tabletLandscape) {
				margin-bottom: 12px;
				display: block;

				.form-control {
					width: 100%;
					margin-left: 0;
				}
			}
		}

		//we style all inputs in _input.scss
		input {
			@include InputBox;
		}
	}
	.dataTables_length {
		select {
			background-color: $light;
			border: 1px solid $passiveLighter;
			box-shadow: none;
			font-size: 13px;
			padding: 6px 10px;
			transition: all 0.2s ease-in-out;

			&:focus {
				outline: 0;
			}
		}
	}

	.panel-heading {
		height: 35px;
		margin: 0 0 0 2px;
		padding: 0;

		.panel-title {
			margin: 0;
			padding: 0;
		}
	}

	.dataTables_info {
		float: left;
	}

	.dataTables_scroll {
		margin-bottom: 10px;
	}

	.dataTables_processing {
		position: absolute;
		top: 0;
		left: 50%;
		width: 100%;
		height: 100%;
		margin-left: -50%;
		text-align: center;
		font-size: 1.2em;
		background-color: rgba(0, 0, 0, 0.1);
		z-index: 5;

		&::before {
			content: '';
			display: block;
			height: 50%;
			width: 100%;
		}
	}
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
	z-index: 1;
}

tr.group,
tr.group:hover {
	background-color: $passiveSoft !important;
}

@media (max-width: 767px) {
	.table-x-scroll {
		overflow-x: scroll;
	}
}

table.dataTable td.dataTables_empty {
	text-align: left;
}

.datatables-link-length-menu {
	li {
		display: inline;
		margin-right: 10px;

		&.active {
			a {
				color: $dark;
				cursor: default;
			}
		}
	}
}

.dt-buttons {
	@media (max-width: $phablet) {
		@include h-clearfix;
	}
}

//pagination cms design
.dataTables_paginate {
	float: right;
	margin-top: 12px;

	.paginate_button {
		display: inline;
		margin-right: 10px;
		color: $passiveMedium;

		* {
			color: inherit;
			background-color: transparent;
			border: none;

			&.disabled {
				cursor: not-allowed;
				text-decoration: none;
			}
		}

		&.first,
		&.last,
		&.previous,
		&.next {
			color: $passiveMedium;
		}

		&.current,
		&.active {
			color: $primary;
			background: transparent;
			font-family: font-bold, sans-serif;
		}

		&:hover,
		&:focus {
			text-decoration: underline;
			cursor: pointer;
			background: transparent;
		}

		&.disabled {
			cursor: not-allowed;
			text-decoration: none;
		}
	}
}

.DataTablesLengthMenu {
	float: right;
	margin-top: 11px;
	margin-right: 10px;

	@media (max-width: $tabletLandscape) {
		margin-bottom: 20px;
	}

	span,
	ul {
		float: left;
	}

	li {
		margin: 0;
		padding: 4px 8px;
		display: inline;

		+ li {
			border-left: 1px solid $passiveDark;
		}

		a {
			color: $passiveDark;
		}

		&.active a {
			font-family: font-bold, sans-serif;
			color: $primary;
		}
	}

	.DataTablesLengthMenu__total {
		border-right: 1px solid $passiveDark;
		padding-right: 8px;
		margin-right: 8px;
	}
}

.dt-button-collection {
	width: 180px;
	margin-top: 0;
	padding: 0;
	box-shadow: none;
	border-radius: 0;
	border: 1px solid $passiveLighter;
}

.dt-button-items {
	float: left;
	width: 100%;
	padding-left: 19px;
	line-height: 42px;
	cursor: pointer;
	color: $passiveMedium;
	text-align: left;
	border: none;

	&:hover {
		background-color: $passiveExtraLight;
	}
}

//This is needed for clicking od refunds label
.DataTable__label {
	span {
		pointer-events: none;
	}
	.expandRowPlus,
	.expandRowMinus {
		position: relative;
		display: inline-block;
		width: 10px;
		height: 13px;
		margin: 0;

		&:before,
		&:after {
			display: inline-block;
			position: absolute;
			top: 0;
			left: 0;
			content: '';
			width: 16px;
			height: 16px;
			border-radius: 50%;
		}

		&:before {
			background: $passiveMedium;
		}
	}

	.expandRowPlus {
		&:after {
			background: url('images/svg/plus-ico.svg') no-repeat center / 8px;
		}
	}

	.expandRowMinus {
		&:after {
			background: url('images/svg/minus-ico.svg') no-repeat center / 8px;
		}
	}

	td {
		background: $primaryExtraLight;
	}
}

//align column with Refund Number icon
td.DataTable--paddingPushRight {
	padding-left: 45px !important;
}

//Copy to clipboard popup
.dt-button-info {
	opacity: 1 !important;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -142px;
	margin-top: -90px;
	background: $light;
	padding: 20px;
	border: 1px solid $borderPanel;
	text-align: center;

	h2 {
		margin-bottom: 30px;
	}
}

.DataTable__iconFlag {
	display: inline-block;
	color: $alternative;
	width: 17px;
	height: 17px;
	background-size: 100%;
	margin-top: 2px;
}
