// Helpers for coloring backgrounds and texts
// NOTE: never use two helpers that can cancel each other
//----------------------------------------------------------------------------------------------------------------------
//
@import "../base/variables";

.h-whiteBg {
	background-color: $light;
}

.h-blueText {
	color: $link;
}

.h-whiteText {
	color: $light;
}

.h-grayText {
	color: $passiveMedium;
}

.h-successColor {
	color: $success;
}

.h-errorColor {
	color: $error;
}

.h-warningColor {
	color: $warning;
}