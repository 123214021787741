//radio and checkbox cmp styles for formsy only because we cant alter DOM structure in formsy
@import '../base/variables';

.radio,
.checkbox {
	label {
		input[type='radio']:before,
		input[type='checkbox']:before {
			//@include CheckBoxBg;
			left: -3px;
			top: -3px;
		}

		input[type='radio']:disabled:before,
		input[type='checkbox']:disabled:before {
			cursor: not-allowed;
			background-color: $passiveSoft;
		}

		input[type='radio']:before {
			border-radius: 50%;
		}

		input[type='radio']:checked:before,
		input[type='checkbox']:checked:before {
			@include CheckBoxActiveIcon;
			left: -3px;
			top: -3px;
		}
	}
}
