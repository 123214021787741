@import '../base/variables';

.EnableDisable {
  display: flex;
  justify-content: center;
}

.EnableDisable__switcherInput {
  width: 60px;
  height: 22px;
  margin: 0 20px;
  border: 1px solid $borderPanel;
  cursor: pointer;
  position: relative;
}

.EnableDisable__switcherIcon {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  position: absolute;
  top: -5px;

  &--enabled {
    background: $success url('images/svg/check-mark-sign-white-ico.svg') no-repeat center center;
    right: -4px;
    background-size: 20px;
  }

  &--disabled {
    background: $error url('images/svg/close-ico-white.svg') no-repeat center center;
    left: -4px;
    background-size: 20px;
  }
}

.EnableDisable__enabledDisabledText {
  width: 60px;
  text-align: center;
}