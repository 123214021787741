// Helpers for text
// NOTE: never use two helpers that can cancel each other
//----------------------------------------------------------------------------------------------------------------------
//
@import '../base/variables';

.h-leftTitle {
	float: left !important;
	padding-right: 50px !important;
}

.h-slogan--lg {
	font-size: 21px !important;
}

.h-slogan--md {
	font-size: 16px;
	font-family: font-bold, sans-serif;
	color: $passiveDark;
}

.h-slogan--sm {
	font-size: 14px;
	font-family: font-bold, sans-serif;
	color: $passiveMedium;
}

.h-bold {
	font-family: font-bold, sans-serif !important;
}

.h-textCenter {
	text-align: center !important;
}

.h-textRight {
	text-align: right !important;
}

.h-textLeft {
	text-align: left !important;
}

.h-textLeft--mobOnly {
	@media (max-width: $tabletPortrait) {
		text-align: left !important;
	}
}

.h-textHide {
	font: 0/0 a !important;
	color: transparent !important;
	text-shadow: none !important;
	background-color: transparent !important;
	border: 0 !important;
}

//TODO: (qqqwwwcssbem) this is FontAwesome related (leave it until we switch to SVG icon)
.fa-13x {
	font-size: 13em !important;
}

.h-noData {
	text-align: center !important;
	color: $passiveSoft !important;
	//display: inline-block !important;
	width: 100% !important;
}

//this is * sign it is used on form required fields
.h-requiredIndicator {
	&::after {
		transition: opacity 0.4s ease-in;
		content: ' *';
		opacity: 1;
	}

	&.active {
		&::after {
			opacity: 0;
		}
	}
}

.h-orSeparator {
	display: inline-block;
	font-family: font-bold, sans-serif;
	font-size: 15px;
	color: $passiveMedium;
	text-align: center;
}

.textEllipsis {
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 185px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	text-align: left;
}