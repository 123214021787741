// SimpleStats is shared component visible when clicked on details button in some table row on FIG
//-------------------------------------------------------------------------------------------------$passiveSoft---------------------
//
@import "../base/variables";

.SimpleStats {

	.SimpleStats__table {
		border: 1px solid $borderPanel;
		font-size: 14px;
		font-weight: normal;
		width: 100%;
	}

	.SimpleStats__th {
		border: 1px solid $borderPanel;
		font-size: 14px;
		font-weight: normal;
		height: 35px;
		background-color: $passiveMedium;
		color: $light;
		width: 158px;
		padding-left: 11px;
		padding-right: 11px;
	}

	.SimpleStats__td {
		border: 1px solid $borderPanel;
		font-size: 14px;
		font-weight: normal;
		height: 35px;
		padding-left: 16px ! important;
		white-space: normal;
		background: $light;
	}

	.SimpleStats__td--empty {
		background-color: $passiveSoft;
		border: 0;
	}
}