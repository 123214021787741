@import '../base/variables';

.LogoPreview {
	background-color: $passiveMedium;
	text-align: center;

	.LogoPreview__img {
		height: 60px;
	}
}

.Partners {
	.Partners__col:first-of-type {
		clear: both;
	}

	.Partners__details {
		text-decoration: none !important;
	}

	.Partners__info {
		background: $passiveLighter;
		background-size: 34px;
		min-height: 20px;
		overflow: auto;
		position: relative;
		@include BoxShadowAnimation(1px, $borderPanel);

		&:hover {
			@include BoxShadowAnimationHover(1px, $passiveMedium);

			.Partners__infoIcon {
				color: $passiveMedium;
			}
		}
	}

	.Partners__infoIcon {
		position: absolute;
		right: 10px;
		left: auto;
		top: 0;
		height: 100% !important;
		color: $passiveMiddle;
	}
}
