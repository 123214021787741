// this is 3rd party scss for FIG located at "FlightMapHolder__map" div
//----------------------------------------------------------------------------------------------------------------------
//
@import '../base/variables';

.ol-overlaycontainer-stopevent {
	position: absolute;
	bottom: 8px;
	right: 0;
	z-index: 5;

	.ol-control {
		width: 62px;
	}

	.ol-zoom {
		.fa {
			display: flex;
			justify-content: center;
		}
	}

	.ol-zoom-in {
		width: 62px;
		height: 30px;
		margin-bottom: 8px;
		background-color: rgba(255, 255, 255, 0.6);
		border: none;
	}

	.ol-zoom-out {
		width: 62px;
		height: 30px;
		background-color: rgba(255, 255, 255, 0.6);
		border: none;
	}

	.ol-rotate,
	.ol-attribution {
		display: none;
	}
}
