@import '../base/variables';

//TODO: omg remove this (one day those styles will be global !!!)
main#cmp,
main#bia {
	.form-control {
		padding: 11px 15px;
		border: 1px solid $borderPanel;
		width: 100%;
		display: block;
		box-shadow: none;
		border-radius: 2px;
		margin-left: 0;

		.validation-message {
			position: absolute;
		}

		&:focus {
			border: 1px solid $passiveLight;
			box-shadow: none;
		}
	}
}

.ReactComboModal {
	.form-group {
		.validation-message {
			position: absolute;
		}
	}
}

.validation-message {
	margin-left: 10px;
	margin-top: 2px;
	color: $light;
	background: $error;
	border: 1px solid $error;
	padding: 5px 3px;
	position: relative;
	text-align: center;
	max-width: 285px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	-moz-box-shadow: 0 0 5px $passiveBlack;
	-webkit-box-shadow: 0 0 5px $passiveBlack;
	box-shadow: 0 0 5px $passiveBlack;
	z-index: 10;
	display: inline-block;

	&:before {
		content: '';
		display: inline-block;
		border-color: transparent transparent $error transparent;
		border-style: solid;
		border-width: 10px;
		height: 0;
		width: 0;
		position: absolute;
		top: -19px;
		left: 10px;
	}
}

.FlightTailRow .validation-message,
.MacAddressRow .validation-message {
	background: transparent;
	border: none;
	box-shadow: none;
	&:before {
		display: none;
	}
}

.help-block {
	line-height: 1.6;
}
