// AppHeader ("Breadcrumbs holder") visible on any app that have Breadcrumbs like BIA for example
//----------------------------------------------------------------------------------------------------------------------
//
@import '../base/variables';

.AppHeader {
	background-color: $light;
	height: 60px;

	.AppHeader__title {
		float: left;
		font-size: 20px;
		line-height: 60px;
		font-weight: normal;
		margin: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 100%;
	}

	.AppHeader__subtitle {
		cursor: pointer;
	}

	.AppHeader__subtitle--strong {
		font-family: font-bold, sans-serif;
		font-weight: normal;
		font-size: 20px;
	}
}

@media (max-width: 570px) {
	.AppHeader__title,
	.AppHeader__subtitle--strong {
		font-size: 10px;
	}
}

//this is hack for depo app only
#depo {
	.AppHeader {
		border-bottom: 1px solid $borderPanel;
	}
	//breadcrumbs hack
	.AppHeader__title {
		font-size: 0;
		color: $passiveMedium;

		&:before {
			content: 'Gogo Flex Developer';
			position: absolute;
			top: 0;
			left: 20px;
			font-size: 20px;
		}
		&:after {
			display: inline-block;
			width: 50px;
			height: 28px;
			position: absolute;
			left: 230px;
			top: 17px;
			border: 2px solid $passiveMedium;
			content: 'BETA';
			font-size: 10px;
			padding-left: 11px;
			line-height: 2.4;
			border-radius: 5px;
			font-family: font-bold, sans-serif;
		}
	}
}
