// Profile menu is visible on all applications, it is located in top right corner of screen
//----------------------------------------------------------------------------------------------------------------------
//
@import '../base/variables';

.ProfileMenu {
	width: 400px;
	position: absolute;
	right: 0;
	top: 60px;
	background-color: $passiveBlack;

	@media (max-width: $mobile) {
		width: 100%;
	}

	.ProfileMenu__head {
		padding: 20px 20px 30px 20px;
	}

	.ProfileMenu__airline {
		text-align: center;
	}

	.ProfileMenu__airlineTitle {
		color: $light;
	}

	.ProfileMenu__bottomPart {
		padding: 0;
	}
}

.UserProfile {
	width: 230px;
	background-color: $passiveDark;
	font-size: 16px;
	line-height: 60px;

	&.open {
		background-color: $passiveBlack;
	}

	//todo: remove !importants after we remove main#cmp selector...
	@media (max-width: $tabletPortrait) {
		min-width: initial !important;
		width: initial !important;
	}

	.UserProfile__link {
		color: $light;
		overflow: hidden;
		height: 60px;
		padding: 0 25px;
		text-overflow: ellipsis;

		&:hover {
			text-decoration: none;
		}

		//on small screen we hide email and show user icon instead
		@media (max-width: $tabletPortrait) {
			.UserProfile__userMail {
				display: none;
			}
		}
	}

	.UserProfile__carretIcon {
		position: absolute;
		right: 5px;
		top: 3px;
		display: block !important;
		left: auto;
		transform: translateY(130%);
	}

	.UserProfile__mobileIcon {
		display: none;
		font-size: 27px;
		position: relative;
		top: 3px;
	}
}

//todo: refactor this, don't use ID in css!!!
main#cmp {
	.UserProfile {
		min-width: 222px;
		background-color: darken($passiveDark, 6);

		&.open {
			background-color: $passiveBlack;
		}
	}
}
